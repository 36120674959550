<template lang="">
  <div>
    <SubHeader :data="data" class="sub-header-responsive" />
    <div class="container">
      <div class="dashboard">
        <div class="list-child-detail">
          <SideBar />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SubHeader from '../components/SubHeader.vue'
import SideBar from '../components/SideBar.vue'
export default {
  name: 'HelpCenterList',
  components: {
    SideBar,
    SubHeader
  },
  data() {
    return {
      data: {
        label_name: 'helpCenter',
        content_desc: 'helpCenterContent',
        img: require('@/assets/image/sub-header/help-center.png')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.dashboard {
  margin-top: 10px;
  padding: 0 30px;
  width: 80%;
  margin: 25px auto;
}
@media screen and (max-width: 992px) {
  .dashboard {
    padding: 0 0px;
    width: 100%;
    margin: 25px auto;
  }
}
.category {
  max-width: 500px;
  color: #fff;
}
</style>
